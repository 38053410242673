@tailwind base;
@tailwind components;
@tailwind utilities;

.default_btn {
  @apply bg-slate-200 font-medium px-4 py-2 text-sm text-gray-900 border-2 rounded-md cursor-pointer hover:border-orange-400
}

.active_btn {
    @apply bg-green-200 font-medium px-4 py-2 text-sm text-gray-900 border-2 rounded-md cursor-pointer hover:border-orange-400
}
  
.default_input {
  @apply rounded-md border-2 px-2 py-1.5 text-gray-900 placeholder:text-gray-400
}

.default_text {
  @apply text-sm font-medium leading-6 text-gray-900
}

.default_link {
  @apply font-semibold leading-6 text-indigo-600 hover:text-indigo-500 hover:cursor-pointer
}

.default_select {
    @apply bg-gray-50 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 px-2 py-1.5
  }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
